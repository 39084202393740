import { render, staticRenderFns } from "./EntidadeLista.vue?vue&type=template&id=6a3c5976"
import script from "./EntidadeLista.vue?vue&type=script&lang=js"
export * from "./EntidadeLista.vue?vue&type=script&lang=js"
import style0 from "./EntidadeLista.vue?vue&type=style&index=0&id=6a3c5976&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports